import React from "react"
import AccordionItem from "./accordionItem"

export default function Accordion({ faqs }) {
  return (
    <>
      {faqs &&
        faqs.map((faq, key) => {
          return (
            <div key={key} className="my-8">
              <AccordionItem faq={faq} />
            </div>
          )
        })}
    </>
  )
}
