import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import App from "../components/app"
import FeatureImg from "../../content/assets/feature.jpg"
import Accordion from "../components/accordion"
import Blurb from "../components/Blurb"

export default function BlogPostTemplate({ data }) {
  const post = data.mdx

  console.log(post.body)
  return (
    <App title={post.frontmatter.title}>
      <div className="max-w-full mx-auto pt-12">
        <img
          src={
            post.frontmatter.featureImage
              ? post.frontmatter.featureImage.childImageSharp.fluid.src
              : FeatureImg
          }
          className="w-full"
          alt="feature_img"
        />
      </div>
      <div className="container mx-auto">
        <div className="mt-8 sm:mt-10 md:mt-14 lg:mt-18 xl:mt-20">
          <div className="text-center">
            <h3 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
              {post.frontmatter.title}
            </h3>
          </div>
        </div>

        <div className="mt-4 sm:mt-6  md:mt-8 lg:mt-10 xl:mt-12">
          <div className="prose prose-lg text-gray-500 mx-auto">
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
        </div>
        {post.frontmatter.faq && (
          <div className="mt-4 sm:mt-6 md:mt-8 lg:mt-10 xl:mt-12">
            <div className="prose prose-lg text-gray-500 mx-auto">
              <Accordion faqs={post.frontmatter.faq} />
            </div>
          </div>
        )}
      </div>
    </App>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featureImage {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              src
            }
          }
        }
        faq {
          question
          answer
        }
        intro {
          blurbs {
            text
            image {
              childImageSharp {
                fluid(quality: 100) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
