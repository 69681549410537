import React, { useState, useRef } from "react"

export default function AccordionItem({ faq }) {
  const [open, setOpen] = useState(false)
  const ansRef = useRef(null)
  return (
    <div className="w-full bg-white shadow-md rounded-lg">
      <button
        onClick={() => setOpen(!open)}
        className="bg-gray-50 flex lg:text-xl p-4 rounded text-gray-900 text-left text-lg w-full"
      >
        {faq.question}
      </button>
      <div
        className="relative overflow-hidden max-h-0 transition-all ease-out duration-300"
        ref={ansRef}
        style={{
          maxHeight: open ? `${ansRef.current.scrollHeight}px` : 0,
        }}
      >
        <div className="p-4">{faq.answer}</div>
      </div>
    </div>
  )
}
